<template>
  <v-app id="title">
    <div class="transparent-layer"></div>
      <h1 class="text">Training Placement Opportunity</h1>
      <v-container>
          <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
              <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
              <v-btn dark text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <v-row align="center" justify="center">
              <v-col cols="12" sm="12">
                  <v-card class="elevation-6 mt-6" >
                      <v-row dense>
                          <v-col col="12" sm="6" dense>
                              <v-overlay :value="overlay">
                                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                              </v-overlay>
                              <v-card-text class="mt-12">
                                  <v-form>
                                      <h4 class="custom-text-color">Forget Password</h4>
                                      <v-row align="center" justify="center">
                                          <v-col col="12" sm="8">
                                              <v-text-field
                                                  v-model="user.uid"
                                                  placeholder="Enter Your Login Id"
                                                  :rules="[rules.required, rules.uid]"
                                                  @click="clearErrorMsg()"
                                                  :hint="disabled == 1 ? 'noneditable' : 'For example, admin@tpo.com'"
                                                  :disabled="disabled == 1 ? true : false"
                                                  clearable
                                                  required
                                                  outlined
                                                  dense
                                                  color="black"
                                                  autocomplete="false"
                                                  class="mt-16"
                                              ></v-text-field>
                                              <v-text-field
                                                  type="number"
                                                  id="enterOTP"
                                                  v-if="hidden1"
                                                  v-model="user.otp"
                                                  placeholder="Enter Your OTP"
                                                  outlined
                                                  dense
                                                  color="black"
                                                  autocomplete="false"
                                                  clearable
                                                  required
                                                  min="1"
                                                  max="6"
                                                  :rules="[v => !!v || 'OTP is required']"
                                                  @change="btndisabled"
                                                  hint="Please check your mail & enter otp."
                                                  class="mt-16"
                                              >
                                              </v-text-field>
                                              <!-- New Layout instead of page routing -->
                                              <v-text-field
                                                  id="newpwd"
                                                  v-if="hidden1"
                                                  v-model="newpwd"
                                                  placeholder="Create new Password"
                                                  clearable
                                                  required
                                                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="() => (value = !value)"
                                                  :type="value ? 'password' : 'text'"
                                                  hint="Combination of Numbers, alphabets and characters is recommended"
                                                  outlined
                                                  dense
                                                  color="black"
                                                  autocomplete="false"
                                                  class="mt-16"
                                              >
                                              </v-text-field>
                                              <v-text-field
                                                  id="cnfmpwd"
                                                  v-if="hidden1"
                                                  v-model="cnfmpwd"
                                                  placeholder="Re Enter new Password"
                                                  clearable
                                                  required
                                                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="() => (value = !value)"
                                                  :type="value ? 'password' : 'text'"
                                                  hint="Re enter your new password"
                                                  outlined
                                                  dense
                                                  color="black"
                                                  autocomplete="false"
                                                  class="mt-16"
                                              >
                                              </v-text-field>
                                              <div class="error-message" v-if="error">{{ error_message }}</div>
                                              <v-layout align-center justify-center v-if="hidden">
                                                  <v-btn class="logi" dark block tile @click="sendotp">Send OTP</v-btn>
                                              </v-layout>
                                              <div class="error-message" v-if="error1">{{ error_message1 }}</div>
                                              <v-layout align-center justify-center v-if="hidden1">
                                                  <v-btn class="logi" dark block tile @click="changePassword" :disabled="btnDisabled == true ? true : false">Change Password</v-btn>
                                              </v-layout>
                                          </v-col>
                                      </v-row>
                                  </v-form>
                              </v-card-text>
                          </v-col>
                          <v-col cols="12" sm="6" class="custom-text-colors " >
                              <div  style="text-align: center; padding: 60px;">
                                  <img src="../assets/logowhite.png" alt="logo" class="log" />
                                  <div style="text-align: center;">
                                      <v-card-text class="white--text">
                                          <h3>Back to Login Page</h3>
                                      </v-card-text>

                                      <router-link to="/" style="text-decoration: none;" title="Back">
                                          <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
                                      </router-link>
                                  </div>
                              </div>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-col>
          </v-row>
      </v-container>
  </v-app>
</template>
<script>
import axios from "axios";
  export default {
    data() {
      return {
        user: {
          uid: "",
          otp: "",
        },
        rules: {
          required: (value) => !!value || "Required.",
          uid: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        },
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        hidden: true,
        hidden1: false,
        disabled: 0,
        btnDisabled: true,
        message: "",
        successMessage: "",
        dbotp: "",
        error: false,
        error_message: "",
        error1: false,
        error_message1: "",
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        newpwd: "",
        cnfmpwd: "",
        value: String,
      };
    },
    methods: {
      perormEmailFormValidation() {
        if (this.user.uid == "") {
          return false;
        } else {
          return true;
        }
      },
      perormOtpFormValidation() {
        if (this.user.otp == "" && this.user.uid == "") {
          return false;
        } else {
          return true;
        }
      },
      btndisabled() {
        this.btnDisabled = false;
      },
      showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
      },
      sendotp() {
        if (this.user.uid == "" ? "" : this.reg.test(this.user.uid)) {
          if (this.perormEmailFormValidation()) {
            //console.log("I am in If block :: " + this.user.uid);
            this.user.dns = window.location.hostname;
            this.overlay = true;
            axios
              .post("/login/forgotpassword", this.user)
              .then((res) => {
                //window.console.log("res" + res.data.msg);
                if (res.data.msg == "200") {
                  //console.log(res.data)
                  this.overlay = false;
                  this.error_message = "";
                  this.showSnackbar("#4caf50", "OTP has been sent on your Email..."); // show snackbar on error
                  localStorage.setItem("EPS-uid", res.data.uid);
                  this.hidden = false;
                  this.hidden1 = true;
                  this.disabled = (this.disabled + 1) % 2;
                  this.dbotp = res.data.otp;
                } else if (res.data.msg == "NAK") {
                  //this.overlay = true;
                  //this.message = "Email Id not found!";
                  this.error = true;
                  this.error_message = "Email address not found!";
                  this.overlay = false;
                } else {
                  this.showSnackbar("red", res.data.msg);
                  this.overlay = false;
                }
              })
              .catch((error) => {
                this.overlay = true;
                // window.console.log(error);
                this.message = "Something went wrong";
              })
              .finally(() => {
                "use strict";
                var overlay = false;
              });
          } else {
            //console.log("I am in else block :: " + this.user.uid);
            this.error = true;
            this.error_message = "Please Enter Valid Email ID..";
          }
        } else {
          //console.log("I am in else block :: " + this.user.uid);
          this.error = true;
          this.error_message = "Please Enter Valid Email ID...";
        }
      },
      changePassword() {
        //console.log("in changePassword");
        if (this.perormOtpFormValidation()) {
          if (this.checkBlankPwdFields()) {
            if (this.matchPwdFields()) {
              const data = {
                   newpwd: this.newpwd,
                   uid: this.user.uid,
                   otp: this.user.otp,
                };
              // console.log(data);
              this.overlay = true;
              axios.post("/login/verifyOTPandChangePassword", data).then((res) => {
                //console.log(res.data);
                if (res.data.msg = "200") {
                  this.overlay = false;
                  //this.showSnackbar("#4caf50", "Password Changed Succussfully"); // show snackbar on error
                  this.sleep(2000);
                  alert("Password changed succussfully...");
                  this.$router.push("/");
                } else if (res.data.msg == "ONM") {
                  this.overlay = false;
                  this.showSnackbar("#b71c1c", "Wrong OTP! Please try again..."); // show snackbar on error
                  document.getElementById("enterOTP").value = "";
                  // console.log(res.data);
                }
              });
            } else {
              this.overlay = false;
              this.showSnackbar("#b71c1c", "Password Doesn't match!"); // show snackbar on error
              document.getElementById("newpwd").value = "";
              document.getElementById("cnfmpwd").value = "";
            }
          } else {
            this.overlay = false;
            this.showSnackbar("#b71c1c", "Please provide Password!"); // show snackbar on error
            document.getElementById("newpwd").value = "";
            document.getElementById("cnfmpwd").value = "";
          }
        }
      },
      checkBlankPwdFields() {
              if (this.newpwd == "" && this.cnfmpwd == "") {
                  return false;
              } else {
                  return true;
              }
      },
      matchPwdFields() {
              if (this.newpwd == this.cnfmpwd) {
                  return true;
              } else {
                  return false;
              }
      },
      sleep(milliseconds) {
              const date = Date.now();
              let currentDate = null;
              do {
                  currentDate = Date.now();
              } while (currentDate - date < milliseconds);
      },
      clearErrorMsg() {
              this.error_message = "";
      },
      },
      mounted() {
          this.message = localStorage.getItem("login-page-msg");
      },
    }
  
</script>
<style scoped>
  .v-application .rounded-bl-xl {
      border-bottom-left-radius: 250px !important;
  }

  .v-application .rounded-br-xl {
      border-bottom-right-radius: 250px !important;
  }

  #title {
      background-image: url("../assets/lapt.jpg");
      background-size: cover;
      background: cover;
      background-repeat: no-repeat;
      position: relative;
  }
  .transparent-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 64, 82, 0.466); 
 }

  .text {
      text-align: center;
      color: white;
      font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      margin-top: 50px;
      z-index: 2; 
  }

  .log {
      margin-bottom: 40px;
      max-width: 210px;
  }
  .custom-text-color{
    color: darkblue;
    font-size: 20px;
    text-align: center;
    
 }
  .custom-text-colors{
   
    background: linear-gradient(112.6deg, rgb(56, 24, 40) -10.7%, rgb(23, 148, 185) 100.2%);
 }
 .logi{
   
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
}
  
</style>
<!-- @media only screen and (max-width: 375px) {
   .alumin{
    max-width: 375px;
  
    align-items: center;
   }
  } -->
